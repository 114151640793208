body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* This will prevent scrolling */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
