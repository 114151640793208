.music-player-wrapper {
    position: absolute;
    bottom: 80px; /* adjust as needed for your layout */
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999; /* ensure it stays above your 3D canvas */
  }
  
  /* 
    The container is a column so the footer text is below the row, 
    but the .music-player-row is in a single horizontal line.
  */
  .music-player-container {
    display: flex;
    flex-direction: column; 
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6); /* transparent black */
    border-radius: 15px;
    padding: 8px 16px;
  }
  
  /* 
    This row holds:
    - Title
    - Time
    - Prev / Play / Next
  */
  .music-player-row {
    display: flex;          /* a single horizontal row */
    align-items: center;
    gap: 10px;              /* space between items */
  }
  
  /* Title styling */
  .song-title {
    color: #fff;
    font-weight: bold;
    font-size: 1.2rem;  /* Increase as desired */
  }
  
  /* Time display styling */
  .time-display {
    color: #fff;
    font-size: 1.2rem;  /* Increase as desired */
  }
  
  /* Footer text below the row */
  .music-player-footer {
    margin-top: 8px;
    text-align: center;
    color: #fff;
    font-weight: normal;
  }