.egocentric-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.floating-buttons {
    position: absolute;
    top: 50%; /* Center vertically */
    right: 20px; /* Position near the right side */
    transform: translateY(-50%); /* Adjust for centering */
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10; /* Ensure it stays above the canvas */
}

.floating-button {
    background-color: #04288b;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.3s;
}

.floating-button:hover {
    background-color: #2e78c6;
}

.floating-button.active {
    background-color: #2e78c6; /* Highlight active button */
    transform: scale(1.1); /* Slightly enlarge active button */
}

.go-back-button {
    position: absolute;
    top: 20px; /* Position at the top */
    right: 20px; /* Position near the right side */
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    z-index: 10; /* Ensure it stays above the canvas */
}

.go-back-button:hover {
    background-color: #cc0000;
}